import * as React from "react"
import Features from "../components/features"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Services from "../components/services"

import planung from "../images/Planung.jpg"
import fertigung from "../images/Fertigung.jpg"
import montage from "../images/Montage.jpg"
import reparatur from "../images/stairs2.jpg"
import YourAdvantages from "../components/your advantages"

const features = [
  {
    name: "Fenstersortiment",
    description:
      "Unser umfassendes Sortiment, mit einer grossen Auswahl an Aluminium, Kunststoff und Kunststoff/Aluminium Fenstern, lässt keine Wünsche offen. ",
  },
  {
    name: "Haustürensortiment",
    description:
      "Adoser bietet Ihnen eine grosse Auswahl an Haus- und Hauseingangstüren. Egal ob Aluminium, Kunststoff oder Kunststoff/Aluminium, gestalten Sie Ihre Türe nach Ihren Wünschen und lassen Sie sich von uns Beraten. ",
  },
  {
    name: "Torsortiment",
    description:
      "Egal ob Sektional-, Schwing-, Seiten Sektional-, Brandschutz oder Roll-Tore für jeden Kunden haben wir das richtige Produkt. ",
  },
  {
    name: "Geländer",
    description:
      "Ob in Stahl, Chromstahl, Aluminium oder Glas…für jeden Geschmack das richtige. Unter Einhaltung der Vorschriften und Normen fertigen wir die Geländer nach Ihren Wünschen. ",
  },
]

const featuresAuftragsAbwicklung = [
  {
    name: "Montage",
    description:
      "Wenn es um die Montage unserer Produkte geht, verstehen wir keinen Spass. All unsere Montageteams sowie Subunternehmer sind erfahrene und bestens geschulte Fachleute.",
  },
  {
    name: "Projekt",
    description:
      "Unsere Projektleiter stehen Ihnen während der ganzen Auftragsabwicklung mit Rat und Tat zur Seite. Unsere Projektverantwortlichen passen Ihr Produkt und den Montageablauf an Ihre Bausituation an.",
  },
]

const featuresList = [
  {
    title: "Persönliche Beratung",
    subTitle: "",
    description: `
            Kundenähe ist nicht nur ein zentrales Anliegen, wir begleiten Sie gerne von der Beratung bis zur Realisation. 
            Auch bei schwierigen Projekten helfen wir Ihnen dabei die passende Lösung zu finden.
            Unsere erfahrenen Mitarbeitenden stehen Ihnen kompetent und mit breit gefächertem Fachwissen zur Seite. Die 
            Persönliche Beratung vor Ort – oder in unserem Ausstellungsrau – ist der Grundstein einer guten Zusammenarbeit.
        `,
    imgPath: planung,
  },
  {
    title: "Umfassendes Sortiment",
    subTitle: "",
    description: `
            Verschiedene Werkstoffe und unzählige Design Möglichkeiten zeichnen uns aus.
            Gestallten Sie Fenster, Türen, Tore und Geländer ganz nach Ihren individuellen Wünschen, 
            WIR ERLEDIGEN DEN REST.
        `,
    imgPath: fertigung,
  },
  {
    title: "Auftragsabwicklung",
    subTitle: "",
    description: `
            Auch das beste Produkt ist nur so gut wie die Montage. Deshalb garantieren unsere bestens 
            geschulten Monteure und Techniker, eine reibungslose Abwicklung vor Ort.
        `,
    imgPath: planung,
  },
  {
    title: "Service",
    subTitle: "",
    description: `
            Damit Sie lange Freude an Ihren Fenstern, Türen und Toren haben sind unsere Mobilen 
            Servicetechniker in der ganzen Schweiz im Auftrag unserer Kunden und Kundinnen unterwegs. 
            Unsere Servicemonteure warten die Fenster, Türen und Tore aller Hersteller und rüsten diese 
            bei Bedarf nach. Egal ob Sie eine Reparatur benötigen oder das Maximum aus Ihren Fenstern, 
            Türen oder Toren herausholen möchten. Unsere Servicetechniker sind gerne für Sie da und erfüllen 
            jedes erdenkliche Bedürfnis und lösen alle Probleme, die ei Fenstern, Türen und Toren Auftreten können.
        `,
    imgPath: fertigung,
  },
  {
    title: "Langjährige Erfahrung",
    subTitle: "",
    description: `
            Seit mehr als 10 Jahren auf dem Markt. Ein klarer Fokus sowie nachhaltige Produkte und Prozesse macht die 
            Marke Adoser zu einem starken und attraktiven Partner nicht nur heute sondern auch in Zukunft.
        `,
    imgPath: fertigung,
  },
]
const IhreVorteilePage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="max-w-7xl mx-auto px-4 py-4 md:px-0 md:py-12">
      <h1 className="text-3xl font-extrabold text-gray-900">Ihre Vorteile</h1>
      <p className="mt-4 text-lg text-gray-500 text-left mb-8">
        Unser umfangreiches Angebot reicht von der Beratung über die Planung bis
        hin zur Realisierung und sogar noch darüber hinaus. Wir zählen zu den
        Marktführern, wenn es um Fenster, Fliegenschutzgitter, Türen, Tore und
        Geländer geht. Erstklassige Leistungen, Qualität und Ihre Zufriedenheit
        sind unser Ansporn. Ihre Zufriedenheit ist unsere Visitenkarte.
      </p>
      <h2 className="text-3xl font-extrabold text-gray-900">
        Langjährige Erfahrung
      </h2>
      <p className="mt-4 text-lg text-gray-500 mb-8">
        Seit mehr als 10 Jahren auf dem Markt Ein klarer Fokus sowie nachhaltige
        Produkte und Prozesse macht die Marke Adoser zu einem starken und
        attraktiven Partner nicht nur heute sondern auch in Zukunft.
      </p>
      <YourAdvantages
        title={"Umfassendes Sortiment"}
        description={
          "Verschiedene Werkstoffe und unzählige Design Möglichkeiten zeichnen uns aus. Gestallten Sie Fenster, Türen, Tore und Geländer ganz nach Ihren individuellen Wünschen, WIR ERLEDIGEN DEN REST."
        }
        features={features}
      />
      <YourAdvantages
        title={"Auftragsabwicklung"}
        description={
          "Auch das beste Produkt ist nur so gut wie die Montage. Deshalb garantieren unsere bestens geschulten Monteure und Techniker, eine reibungslose Abwicklung vor Ort. "
        }
        features={featuresAuftragsAbwicklung}
      />

      <h2 className="text-3xl font-extrabold text-gray-900">Service</h2>
      <p className="mt-4 text-lg text-gray-500 mb-8">
        Damit Sie lange Freude an Ihren Fenstern, Türen und Toren haben sind
        unsere Mobilen Servicetechniker in der ganzen Schweiz im Auftrag unserer
        Kunden und Kundinnen unterwegs. Unsere Servicemonteure warten die
        Fenster, Türen und Tore aller Hersteller und rüsten diese bei Bedarf
        nach. Egal ob Sie eine Reparatur benötigen oder das Maximum aus Ihren
        Fenstern, Türen oder Toren herausholen möchten. Unsere Servicetechniker
        sind gerne für Sie da und erfüllen jedes erdenkliche Bedürfnis und lösen
        alle Probleme, die ei Fenstern, Türen und Toren Auftreten können
      </p>
    </div>
  </Layout>
)

export default IhreVorteilePage
