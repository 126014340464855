/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { v4 as uuidv4 } from "uuid"

export default function Features({ features }) {
  return (
    <div className="bg-gray-800">
      <div
        key={uuidv4()}
        className="max-w-7xl mx-auto px-4 py-4 md:px-0 md:py-12 grid md:grid-cols-2 gap-5 h-50 mb-1 md:mb-1"
      >
        {features.map((feature, index) => (
          <>
            <div>
              <div className="px-4 py-8 lg:px-0">
                <h2 className="text-white text-3xl font-bold mb-4">
                  {feature.title}
                </h2>
                <span className="text-white text-base">{feature.subTitle}</span>
                <p className="text-white text-base">{feature.description}</p>
              </div>
            </div>
            <div className="px-4 py-8">
              <img
                className="w-full h-50 object-cover"
                src={feature.imgPath}
                alt=""
              />
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
