/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline"
import React from "react"

export default function YourAdvantages({ title, description, features }) {
  return (
    <div className="bg-white">
      <h2 className="text-3xl font-extrabold text-gray-900">{title}</h2>
      <p className="mt-4 text-lg text-gray-500">{description}</p>

      <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
        {features.map(feature => (
          <div key={feature.name} className="relative">
            <dt>
              <CheckIcon
                className="absolute h-6 w-6 text-green-500"
                aria-hidden="true"
              />
              <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                {feature.name}
              </p>
            </dt>
            <dd className="mt-2 ml-9 text-base text-gray-500">
              {feature.description}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
